<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section mycity_smi">
          <h2>Средства массовой информации</h2>
          <div v-if="smi && smi.data && smi.data.length" class="row">
            <div class="col-12">
              <div class="item" v-for="(item, index) in smi.data" :key="index">
                <a :href="item.link" class="title">{{ item.title }}</a>
              </div>
            </div>
          </div>
          <p class="_item__text" v-else>Средства массовой информации отсутствуют</p>
        </section>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "SMI",
  async asyncData({ store }) {
    await store.dispatch("GET_MYCITY_SMI_PAGE");
  },
  components: {},
  beforeRouteLeave(to, from, next) {
    this.$store.state.smi_page = [];
    next();
  },
  computed: {
    smi() {
      return this.$store.state.smi_page;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/mycity/smi/smi.styl"
</style>
